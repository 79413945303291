import React, { createContext, useContext, useEffect, useState } from "react";

interface ProviderProps {
  children: React.ReactNode;
}

export const CartContext = createContext({
  data: {
    step: 0,
    price: 0,
    hash: "",
  },
  getStep: (): number => {
    return 0;
  },
  checkPrice: (): number => {
    return 0;
  },
  getData: () => {},
  cartSetData: (values: any) => {},
  setPrice: (value: number) => {},
  setHash: (value: string) => {},
  increaseStep: () => {},
  decreaseStep: () => {},
});

const InitialState = {
  step: 0,
  price: 10,
  hash: "",
};

const STORAGE_KEY = "cart-context";

export const CartProvider = ({ children }: ProviderProps) => {
  const [state, setState] = useState({
    data: JSON.parse(
      global.localStorage?.getItem(STORAGE_KEY) || JSON.stringify(InitialState),
    ),
    getStep: (): number => {
      return 0;
    },
    checkPrice: (): number => {
      return 0;
    },
    cartSetData: (values: any) => {},
    increaseStep: () => {},
    getData: () => {},
    decreaseStep: () => {},
  });

  useEffect(() => {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(state.data));
  }, [state]);

  const getStep = () => {
    const { step } = state.data;
    return step;
  };

  const checkPrice = () => {
    const { price } = state.data;
    return price;
  };

  const getData = () => {
    return state.data;
  };

  const setPrice = (value: number) => {
    setState(() => ({
      ...state,
      data: {
        ...state.data,
        price: value,
      },
    }));
  };

  const setHash = (value: string) => {
    setState(() => ({
      ...state,
      data: {
        ...state.data,
        hash: value,
      },
    }));
  };

  const increaseStep = () => {
    setState(() => ({
      ...state,
      data: {
        ...state.data,
        step: state.data.step + 1,
      },
    }));
  };

  const decreaseStep = () => {
    setState(() => ({
      ...state,
      data: {
        ...state.data,
        step: state.data.step - 1,
      },
    }));
  };

  const cartSetData = (values) => {
    setState(() => ({
      ...state,
      data: {
        ...state.data,
        ...values,
      },
    }));
  };

  return (
    <CartContext.Provider
      value={{
        data: state.data,
        getStep,
        cartSetData,
        checkPrice,
        increaseStep,
        decreaseStep,
        setPrice,
        setHash,
        getData,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
