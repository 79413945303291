import { SignInInterface, SignUpInterface } from "./Auth";
import axios from "axios";
import jwt from "jwt-decode";
import { toast } from "react-toastify";

export interface AuthApiInterface {
  signIn(signIn: SignInInterface): Promise<SignInInterface>;

  signUp(signUp: SignUpInterface): Promise<SignUpInterface>;

  getAccount(): boolean;

  getUserInfo(): any;

  logout(): void;
}

export class AccountService implements AuthApiInterface {
  constructor(private baseUrl: string) {}

  async signIn(signIn: SignInInterface): Promise<SignInInterface> {
    return await axios
      .post(`${this.baseUrl}/v1/auth/signIn`, signIn)
      .then(({ data }) => {
        localStorage.setItem("obeg-auth", JSON.stringify(data));
        return data;
      });
  }

  async signUp(signUp: SignUpInterface): Promise<SignUpInterface> {
    return await axios
      .post(`${this.baseUrl}/v1/auth/signUp`, signUp)
      .then(({ data }) => data);
  }

  getAccount(): boolean {
    const { accessToken } =
      JSON.parse(localStorage.getItem("obeg-auth") as any) || [];
    if (accessToken) {
      //set JWT token to localF
      const user = jwt(accessToken);
      if (!user) {
        return false;
      }
      const { exp } = user as any;
      if (new Date() <= new Date(exp * 1000)) {
        return true;
      } else {
        localStorage.removeItem("obeg-auth");
        toast.warning("Zostałeś wylogowany, zaloguj się ponownie!");
        return;
      }
    }
    return false;
  }

  async getUserInfo(): Promise<any> {
    const { accessToken } =
      JSON.parse(localStorage.getItem("obeg-auth") as any) || [];
    if (accessToken) {
      return await axios
        .get(`${this.baseUrl}/v1/user/@me`)
        .then(({ data }) => data);
    }
  }

  logout(): void {
    localStorage.removeItem("obeg-auth");
    window.location.reload();
    return;
  }
}
