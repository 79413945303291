import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../Utils/Assets/AssetHelpers";
import {
  Button,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import "./Header.scss";
import React, { FC, useEffect, useRef, useState } from "react";
import { HiMenuAlt3, HiOutlineChevronDown } from "react-icons/hi";
import { BsArrowRightShort, BsFillPersonFill } from "react-icons/bs";
import { useApi } from "../../Sdk/React/Hooks/ApiContext";
import clsx from "clsx";
import { useLocation } from "react-router";
import { useAccount } from "../../App/Context/AccountContext/Account.context";
import CountdownTimer from "../CountdownTimer/CountdownTimer";

interface HeaderProps {
  isAuth?: boolean;
  isCart?: boolean;
}

const defaultProps: HeaderProps = {
  isAuth: false,
  isCart: false,
};

const Header: FC<HeaderProps> = (props) => {
  const { data } = useAccount();
  const location = useLocation();
  const [scrollPosition, setScrollPosition] = useState(-1);
  const [navHeight, setNavHeight] = useState(0);
  const [showNav, setShowNav] = useState(false);
  const ref = useRef<any>(null);
  const { auth } = useApi();
  const { getAccount, logout } = auth;
  const isLogged = getAccount();
  const expand = "lg";
  props = { ...defaultProps, ...props };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollPosition(Math.round(window.scrollY));
    });
    if (ref.current) {
      setNavHeight(ref.current.clientHeight as number);
    }
  }, []);

  const NavbarElement = () => {
    return (
      <div className={"navbar-right"}>
        <BsFillPersonFill />
        <span>{data.email}</span>
        <HiOutlineChevronDown />
      </div>
    );
  };
  const toggleOffCanvas = () => {
    setShowNav((showNav) => !showNav);
  };

  useEffect(() => {
    if (showNav) {
      setShowNav(false);
    }
  }, [location]);

  return (
    <>
      {/*<CountdownTimer targetDate={new Date("2022-09-01T12:00:00").getTime()} />*/}
      <Navbar
        ref={ref}
        expand={expand}
        className={clsx("nav", scrollPosition >= navHeight ? "sticky-top" : "")}
      >
        <Container fluid>
          <Navbar.Brand>
            <Link to={"/"} onClick={() => window.scrollTo(0, 0)}>
              <img
                src={toAbsoluteUrl("/media/logo/header-logo.png")}
                height={"48px"}
                width={"270px"}
                alt="logo obeg"
                loading="lazy"
              />
            </Link>
          </Navbar.Brand>
          {!props.isAuth && (
            <Navbar.Toggle
              onClick={toggleOffCanvas}
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              data-bs-target={`offcanvasNavbar-expand-${expand}`}
            >
              <HiMenuAlt3 />
            </Navbar.Toggle>
          )}
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
            show={showNav ? true : ""}
            onHide={toggleOffCanvas}
          >
            <Offcanvas.Header closeButton className={"ms-auto"} />
            <Offcanvas.Body>
              {!props.isAuth && (
                <Nav className="justify-content-end flex-grow-1">
                  <div
                    className={
                      "d-flex justify-content-center align-items-start align-items-lg-center mx-lg-auto col-12 col-lg-auto mb-5 mb-lg-0 flex-column flex-lg-row"
                    }
                  >
                    <Link
                      className="me-xl-2 py-2 navbar-item col-12 col-lg-auto d-flex"
                      to="/nowa-baza-firm"
                    >
                      Nowa baza firm{" "}
                      <BsArrowRightShort className={"d-lg-none ms-auto"} />
                    </Link>
                    <Link
                      className="me-xl-2 py-2 navbar-item col-12 col-lg-auto d-flex"
                      to="/kontakt"
                    >
                      Kontakt{" "}
                      <BsArrowRightShort className={"d-lg-none ms-auto"} />
                    </Link>
                    <Link
                      className="me-xl-2 py-2 navbar-item col-12 col-lg-auto d-flex"
                      to="/cennik"
                    >
                      Cennik{" "}
                      <BsArrowRightShort className={"d-lg-none ms-auto"} />
                    </Link>
                    <Link
                      className="me-xl-2 py-2 navbar-item col-12 col-lg-auto d-flex"
                      to="/faq"
                    >
                      FAQ <BsArrowRightShort className={"d-lg-none ms-auto"} />
                    </Link>
                    <Link
                      className="me-xl-2 py-2 navbar-item col-12 col-lg-auto d-flex"
                      to="/baza-firm/branze"
                    >
                      Katalog{" "}
                      <BsArrowRightShort className={"d-lg-none ms-auto"} />
                    </Link>
                    {isLogged && (
                      <Link
                        className="me-xl-2 py-2 navbar-item col-12 col-lg-auto d-flex"
                        to="/konto?view=Base"
                      >
                        Moje bazy
                        <BsArrowRightShort className={"d-lg-none ms-auto"} />
                      </Link>
                    )}
                    <hr className={"w-100 d-flex d-lg-none"} />
                    {isLogged ? (
                      <div className={"d-flex flex-column d-lg-none w-100"}>
                        <Link
                          className="me-xl-2 py-2 navbar-item  col-lg-auto d-flex"
                          to={"/konto"}
                        >
                          Konto{" "}
                          <BsArrowRightShort className={"d-lg-none ms-auto"} />
                        </Link>

                        <Link
                          className="me-xl-2 py-2 navbar-item col-12 col-lg-auto d-flex"
                          to={""}
                          onClick={() => logout()}
                        >
                          Wyloguj{" "}
                          <BsArrowRightShort className={"d-lg-none ms-auto"} />
                        </Link>
                      </div>
                    ) : (
                      <div className={"d-flex flex-column d-lg-none w-100"}>
                        <Link
                          className="me-xl-2 py-2 navbar-item col-12 col-lg-auto d-flex"
                          to={"/logowanie"}
                          rel={"nofollow"}
                        >
                          Zaloguj się
                          <BsArrowRightShort className={"d-lg-none ms-auto"} />
                        </Link>

                        <Link
                          className="me-xl-2 py-2 navbar-item col-12 col-lg-auto d-flex"
                          to={"/rejestracja"}
                          rel={"nofollow"}
                        >
                          {window.screen.width >= 992
                            ? "Załóż konto"
                            : "Wypróbuj za darmo"}
                          <BsArrowRightShort className={"d-lg-none ms-auto"} />
                        </Link>
                      </div>
                    )}
                  </div>
                  {/*<NavDropdown title="Dropdown" id="nav-dropdown">*/}
                  {/*  <NavDropdown.Item eventKey="4.1">Action</NavDropdown.Item>*/}
                  {/*  <NavDropdown.Item eventKey="4.2">*/}
                  {/*    Another action*/}
                  {/*  </NavDropdown.Item>*/}
                  {/*  <NavDropdown.Item eventKey="4.3">*/}
                  {/*    Something else here*/}
                  {/*  </NavDropdown.Item>*/}
                  {/*  <NavDropdown.Divider />*/}
                  {/*  <NavDropdown.Item eventKey="4.4">*/}
                  {/*    Separated link*/}
                  {/*  </NavDropdown.Item>*/}
                  {/*</NavDropdown>*/}
                  <div
                    className={
                      "d-lg-flex justify-content-end col-12 col-lg-auto flex-column-reverse flex-lg-row d-none"
                    }
                  >
                    {isLogged ? (
                      <div className={"d-flex align-items-center "}>
                        <NavDropdown
                          title={<NavbarElement />}
                          rootCloseEvent={"click"}
                          id="nav-dropdown"
                        >
                          <NavDropdown.Item as={Link} to={"/konto"}>
                            Konto
                          </NavDropdown.Item>

                          <NavDropdown.Item onClick={() => logout()}>
                            Wyloguj
                          </NavDropdown.Item>
                        </NavDropdown>
                      </div>
                    ) : (
                      <>
                        <Button
                          className={"px-0 py-0 nav-login me-0 me-xl-4"}
                          variant={"link"}
                        >
                          <Link
                            className={""}
                            to={"/logowanie"}
                            rel={"nofollow"}
                          >
                            Zaloguj się
                          </Link>
                        </Button>
                        <Button
                          className={"px-0 py-0 nav-register"}
                          variant={"danger"}
                        >
                          <Link
                            className={"px-3 px-xl-5 py-2"}
                            to={"/rejestracja"}
                            rel={"nofollow"}
                          >
                            {window.screen.width >= 992
                              ? "Załóż konto"
                              : "Wypróbuj za darmo"}
                          </Link>
                        </Button>
                      </>
                    )}
                  </div>
                </Nav>
              )}
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export { Header };
