export const toAbsoluteUrl = (pathname: string) => pathname;

export const getPrice = (price: number | undefined) => {
  return `${Number(price).toFixed(2).replace(".", ",")} zł`;
};

export const getDateString = (date) => {
  return new Date(date).toLocaleDateString("sv");
};

export const monthShort = [
  "Sty",
  "Lut",
  "Mar",
  "Kwi",
  "Maj",
  "Cze",
  "Lip",
  "Sie",
  "Wrz",
  "Paź",
  "Lis",
  "Gru",
];
