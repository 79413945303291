export const LegalForm = {
  JDG: "JEDNOOSOBOWA DZIAŁALNOŚĆ GOSPODARCZA",
  "SPÓŁKA JAWNA": "SPÓŁKA JAWNA",
  "SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ":
    "SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ",
  "SAMODZIELNY PUBLICZNY ZAKŁAD OPIEKI ZDROWOTNEJ":
    "SAMODZIELNY PUBLICZNY ZAKŁAD OPIEKI ZDROWOTNEJ",
  "SPÓŁKA PARTNERSKA": "SPÓŁKA PARTNERSKA",
  "PRZEDSIĘBIORSTWO PAŃSTWOWE": "PRZEDSIĘBIORSTWO PAŃSTWOWE",
  "SPÓŁKA AKCYJNA": "SPÓŁKA AKCYJNA",
  STOWARZYSZENIE: "STOWARZYSZENIE",
  "STOWARZYSZENIE KULTURY FIZYCZNEJ": "STOWARZYSZENIE KULTURY FIZYCZNEJ",
  "ZWIĄZEK ZAWODOWY": "ZWIĄZEK ZAWODOWY",
  FUNDACJA: "FUNDACJA",
  "SPÓŁKA KOMANDYTOWA": "SPÓŁKA KOMANDYTOWA",
  "ROLNICZE ZRZESZENIE BRANŻOWE": "ROLNICZE ZRZESZENIE BRANŻOWE",
  SPÓŁDZIELNIA: "SPÓŁDZIELNIA",
  "ZWIĄZEK SPORTOWY": "ZWIĄZEK SPORTOWY",
  "KÓŁKO ROLNICZE": "KÓŁKO ROLNICZE",
  "CECH RZEMIEŚLNICZY": "CECH RZEMIEŚLNICZY",
  "IZBA GOSPODARCZA": "IZBA GOSPODARCZA",
  "SPÓŁDZIELCZA KASA OSZCZĘDNOŚCIOWO-KREDYTOWA":
    "SPÓŁDZIELCZA KASA OSZCZĘDNOŚCIOWO-KREDYTOWA",
  "ZWIĄZKI ROLNIKÓW": "ZWIĄZKI ROLNIKÓW",
  "KÓŁEK I ORGANIZACJI ROLNICZYCH": "KÓŁEK I ORGANIZACJI ROLNICZYCH",
  "NIE OKREŚLONA - RDN": "NIE OKREŚLONA - RDN",
  "STOWARZYSZENIE KULTURY FIZYCZNEJ O ZASIĘGU OGÓLNOKRAJOWYM":
    "STOWARZYSZENIE KULTURY FIZYCZNEJ O ZASIĘGU OGÓLNOKRAJOWYM",
  "ZWIĄZEK PRACODAWCÓW": "ZWIĄZEK PRACODAWCÓW",
  "ZWIĄZEK STOWARZYSZEŃ": "ZWIĄZEK STOWARZYSZEŃ",
  "ZRZESZENIE HANDLU I USŁUG": "ZRZESZENIE HANDLU I USŁUG",
  "JEDNOSTKA BADAWCZO-ROZWOJOWA": "JEDNOSTKA BADAWCZO-ROZWOJOWA",
  "ZRZESZENIE TRANSPORTU": "ZRZESZENIE TRANSPORTU",
  "JEDNOSTKA ORGANIZACYJNA ZWIĄZKU ZAWODOWEGO POSIADAJĄCA OSOBOWOŚĆ PRAWNĄ":
    "JEDNOSTKA ORGANIZACYJNA ZWIĄZKU ZAWODOWEGO POSIADAJĄCA OSOBOWOŚĆ PRAWNĄ",
  "KOLUMNA TRANSPORTU SANITARNEGO": "KOLUMNA TRANSPORTU SANITARNEGO",
  "IZBA RZEMIEŚLNICZA": "IZBA RZEMIEŚLNICZA",
  "OGÓLNOKRAJOWY ZWIĄZEK MIĘDZYBRANŻOWY":
    "OGÓLNOKRAJOWY ZWIĄZEK MIĘDZYBRANŻOWY",
  "POLSKI ZWIĄZEK SPORTOWY": "POLSKI ZWIĄZEK SPORTOWY",
  "ZWIĄZEK ROLNICZYCH ZRZESZEŃ BRANŻOWYCH":
    "ZWIĄZEK ROLNICZYCH ZRZESZEŃ BRANŻOWYCH",
  "INSTYTUT BADAWCZY / INSTYTUT DZIAŁAJĄCY W RAMACH SIECI BADAWCZEJ ŁUKASIEWICZ":
    "INSTYTUT BADAWCZY / INSTYTUT DZIAŁAJĄCY W RAMACH SIECI BADAWCZEJ ŁUKASIEWICZ",
  "INNA ORGANIZACJA SPOŁECZNA LUB ZAWODOWA":
    "INNA ORGANIZACJA SPOŁECZNA LUB ZAWODOWA",
  "SPÓŁKA KOMANDYTOWO-AKCYJNA": "SPÓŁKA KOMANDYTOWO-AKCYJNA",
  "ZWIĄZEK ZAWODOWY ROLNIKÓW INDYWIDUALNYCH":
    "ZWIĄZEK ZAWODOWY ROLNIKÓW INDYWIDUALNYCH",
  "JEDNOSTKA TERENOWA STOWARZYSZENIA POSIADAJĄCA OSOBOWOŚĆ PRAWNĄ":
    "JEDNOSTKA TERENOWA STOWARZYSZENIA POSIADAJĄCA OSOBOWOŚĆ PRAWNĄ",
  "TOWARZYSTWO UBEZPIECZEŃ WZAJEMNYCH": "TOWARZYSTWO UBEZPIECZEŃ WZAJEMNYCH",
  "OGÓLNOKRAJOWE ZRZESZENIE MIĘDZYBRANŻOWE":
    "OGÓLNOKRAJOWE ZRZESZENIE MIĘDZYBRANŻOWE",
  "FEDERACJA / KONFEDERACJA ZWIĄZKÓW PRACODAWCÓW":
    "FEDERACJA / KONFEDERACJA ZWIĄZKÓW PRACODAWCÓW",
  "INNA ORGANIZACJA PODMIOTÓW GOSPODARCZYCH":
    "INNA ORGANIZACJA PODMIOTÓW GOSPODARCZYCH",
  "ZWIĄZEK RZEMIOSŁA POLSKIEGO": "ZWIĄZEK RZEMIOSŁA POLSKIEGO",
  "OGÓLNOKRAJOWA REPREZENTACJA ZRZESZEŃ TRANSPORTU":
    "OGÓLNOKRAJOWA REPREZENTACJA ZRZESZEŃ TRANSPORTU",
  "OGÓLNOKRAJOWA REPREZENTACJA ZRZESZEŃ HANDLU I USŁUG":
    "OGÓLNOKRAJOWA REPREZENTACJA ZRZESZEŃ HANDLU I USŁUG",
  "INNA OSOBA PRAWNA BĘDĄCA ORGANIZACJĄ POŻYTKU PUBLICZNEGO":
    "INNA OSOBA PRAWNA BĘDĄCA ORGANIZACJĄ POŻYTKU PUBLICZNEGO",
  "KOŚCIELNA OSOBA PRAWNA BĘDĄCA ORGANIZACJĄ POŻYTKU PUBLICZNEGO":
    "KOŚCIELNA OSOBA PRAWNA BĘDĄCA ORGANIZACJĄ POŻYTKU PUBLICZNEGO",
  "NIE POSIADAJĄCA OSOBOWOŚCI PRAWNEJ INSTYTUCJA KOŚCIELNA BĘDĄCA ORGANIZACJĄ POŻYTKU PUBLICZNEGO":
    "NIE POSIADAJĄCA OSOBOWOŚCI PRAWNEJ INSTYTUCJA KOŚCIELNA BĘDĄCA ORGANIZACJĄ POŻYTKU PUBLICZNEGO",
  "NIEPOSIADAJĄCA OSOBOWOŚCI PRAWNEJ INSTYTUCJA KOŚCIELNA BĘDĄCA ORGANIZACJĄ POŻYTKU PUBLICZNEGO":
    "NIEPOSIADAJĄCA OSOBOWOŚCI PRAWNEJ INSTYTUCJA KOŚCIELNA BĘDĄCA ORGANIZACJĄ POŻYTKU PUBLICZNEGO",
  "NIE POSIADAJĄCA OSOBOWOŚCI PRAWNEJ ORGANIZACJA POŻYTKU PUBLICZNEGO":
    "NIE POSIADAJĄCA OSOBOWOŚCI PRAWNEJ ORGANIZACJA POŻYTKU PUBLICZNEGO",
  "EUROPEJSKIE ZGRUPOWANIE INTERESÓW GOSPODARCZYCH":
    "EUROPEJSKIE ZGRUPOWANIE INTERESÓW GOSPODARCZYCH",
  "STOWARZYSZENIE OGRODOWE": "STOWARZYSZENIE OGRODOWE",
  "SPÓŁKA EUROPEJSKA": "SPÓŁKA EUROPEJSKA",
  "NIEPOSIADAJĄCA OSOBOWOŚCI PRAWNEJ ORGANIZACJA POŻYTKU PUBLICZNEGO":
    "NIEPOSIADAJĄCA OSOBOWOŚCI PRAWNEJ ORGANIZACJA POŻYTKU PUBLICZNEGO",
  "INSTYTUCJA GOSPODARKI BUDŻETOWEJ": "INSTYTUCJA GOSPODARKI BUDŻETOWEJ",
  "SPÓŁDZIELNIA EUROPEJSKA": "SPÓŁDZIELNIA EUROPEJSKA",
  "PROSTA SPÓŁKA AKCYJNA": "PROSTA SPÓŁKA AKCYJNA",
};

export const getEnumKeyByEnumValue = (
  myEnum: any,
  enumValue: string,
): string => {
  const entries = Object.entries(myEnum);
  for (const [key, value] of entries) {
    if (value === enumValue) {
      return key;
    }
  }
  return "";
};
