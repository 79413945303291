export interface FilterInterface {
  _id?: string;
  hash?: string;
  status: EntityStatusEnum[];
  has_telephone_number?: boolean | string;
  has_email_address?: boolean | string;
  has_website_address?: boolean | string;
  from?: string;
  to?: string;
  main_pkd?: string[];
  legalForm?: string[];
  pkd?: string[];
  location?: LocationFilter[];
}
export interface NumberValueFilter {
  count?: number;
  price?: number;
}
export interface FilterSummaryInterface {
  _id: string;
  hash: string;
  total: NumberValueFilter;
  basicData: NumberValueFilter;
  registrationNumbers: NumberValueFilter;
  addressData: NumberValueFilter;
  websiteAddresses: NumberValueFilter;
  emailAddresses: NumberValueFilter;
  telephoneNumbers: NumberValueFilter;
  createdAt: string;
  filterHash: string;
}
export interface FilterMeInterface {
  name: string;
  filterHash: string;
}
export interface LocationFilter {
  text?: string;
}
export enum EntityStatusEnum {
  ACTIVE = 1,
  CLOSED = 2,
  SUSPENDED = 3,
  PENDING = 4,
  PARTNERSHIP = 9,
  IN_LIQUIDATION = 20,
  IN_BANKRUPTCY = 21,
}
