import React, { lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { NewBaseProvider } from "./Context/NewBaseContext/NewBase.context";
import { ToastContainer } from "react-toastify";
import Loading from "../Components/Loading/Loading";
import { HelmetProvider } from "react-helmet-async";
import { AccountProvider } from "./Context/AccountContext/Account.context";
import { ErrorLayout } from "../Components/ErrorLayout/ErrorLayout";
import { ErrorBoundary } from "react-error-boundary";
import Routes from "./Routes/Routes";
import CountdownTimer from "../Components/CountdownTimer/CountdownTimer";

const WithNotifications: React.FC = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        closeButton={false}
        draggable
        pauseOnHover
      />
    </>
  );
};

const App = () => {
  return (
    <ErrorBoundary fallback={<ErrorLayout />}>
      <Suspense fallback={<Loading />}>
        <WithNotifications />
        <AccountProvider>
          <NewBaseProvider>
            <HelmetProvider>
              <Routes />
            </HelmetProvider>
          </NewBaseProvider>
        </AccountProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

export { App };
