import axios from "axios";
import { AddressInterface } from "./Address";

export interface AddressApiInterface {
  getAddress(params: { text: string } | undefined): Promise<AddressInterface>;
}

export class AddressService implements AddressApiInterface {
  constructor(private baseUrl: string) {}

  async getAddress(
    params: { text: string } | undefined,
  ): Promise<AddressInterface> {
    return await axios
      .get(`${this.baseUrl}/v1/address`, {
        params,
      })
      .then(({ data }) => data);
  }
}
