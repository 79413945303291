import React, { createContext, FC, useContext, useState } from "react";
import { ObegApi } from "../../Core";

interface ProviderProps {
  children: React.ReactNode;
}

const InitialState = new ObegApi({
  baseUrl: process.env.API_URL as string,
});

const ApiContext = createContext<ObegApi>(InitialState as any);

const ApiProvider: FC<ProviderProps> = ({ children }) => {
  const [state] = useState(InitialState);
  return <ApiContext.Provider value={state}>{children}</ApiContext.Provider>;
};

export { ApiContext, ApiProvider };

export function useApi() {
  return useContext(ApiContext);
}
