import axios from "axios";
import qs from "qs";
import {
  FilterInterface,
  FilterSummaryInterface,
  FilterMeInterface,
} from "./Filter";

export interface FilterApiInterface {
  createFilter(filter: FilterInterface): Promise<FilterInterface>;
  getFilter(filterHash: string): Promise<FilterInterface>;
  getFilterSummary(filterHash: string): Promise<FilterSummaryInterface>;
  getFilterSummary(filterHash: string): Promise<FilterSummaryInterface>;
  postDataSheetMeByHash(filterHash: string): Promise<any>;
  getFilterMe(): Promise<any>;
  getFilterMeId(id: string): Promise<any>;
  getFilterMeByHash(value: string): Promise<any>;
  createFilterMe(values: FilterMeInterface): Promise<FilterMeInterface>;
  postDataSheetMe(params: any): Promise<any>;
  getDatasheetSummaryByHash(datasheetHash: string): Promise<any>;
  postDataSheetPublish(datasheetHash: string): Promise<any>;
  getDataSheetByHash(filterHash: string): Promise<any>;
  postUpdateDataSheetByHash(
    datasheetHash: string,
    updateDatasheetDto: any,
  ): Promise<any>;
}

export class FilterService implements FilterApiInterface {
  constructor(private baseUrl: string) {}

  async createFilter(filter: FilterInterface): Promise<FilterInterface> {
    return await axios
      .post<FilterInterface>(`${this.baseUrl}/v1/filter`, filter)
      .then(({ data }) => data);
  }

  async getFilter(filterHash: string): Promise<FilterInterface> {
    return await axios
      .post<FilterInterface>(`${this.baseUrl}/v1/filter/:getByHash`, {
        filterHash,
      })
      .then(({ data }) => data);
  }

  async getFilterSummary(filterHash: string): Promise<FilterSummaryInterface> {
    return await axios
      .post<FilterSummaryInterface>(
        `${this.baseUrl}/v1/filter-summary/:getByFilterHash`,
        {
          filterHash,
        },
      )
      .then(({ data }) => data);
  }

  async getDatasheetSummaryByHash(
    datasheetHash: string,
  ): Promise<FilterSummaryInterface> {
    return await axios
      .post<FilterSummaryInterface>(
        `${this.baseUrl}/v1/datasheet-summary/@me/:getByDatasheetHash`,
        {
          datasheetHash,
        },
      )
      .then(({ data }) => data);
  }

  async getFilterSummaryMeFilter(
    id: string,
    params?: any,
  ): Promise<FilterSummaryInterface> {
    return await axios
      .get<FilterSummaryInterface>(
        `${this.baseUrl}/v1/filter-summary/@me/filter/${id}`,
        {
          params,
          paramsSerializer: (params) =>
            qs.stringify(params, { encode: false, arrayFormat: "repeat" }),
        },
      )
      .then(({ data }) => data);
  }

  async postDataSheetMe(params: any): Promise<FilterSummaryInterface> {
    return await axios
      .post<FilterSummaryInterface>(`${this.baseUrl}/v1/datasheet/@me`, {
        ...params,
        paramsSerializer: (params) =>
          qs.stringify(params, { encode: false, arrayFormat: "repeat" }),
      })
      .then(({ data }) => data);
  }

  async postDataSheetMeByHash(
    datasheetHash: string,
  ): Promise<FilterSummaryInterface> {
    return await axios
      .post<FilterSummaryInterface>(
        `${this.baseUrl}/v1/datasheet/@me/:getByHash`,
        {
          datasheetHash,
        },
      )
      .then(({ data }) => data);
  }

  async postDataSheetPublish(
    datasheetHash: string,
  ): Promise<FilterSummaryInterface> {
    return await axios
      .post<FilterSummaryInterface>(
        `${this.baseUrl}/v1/datasheet/@me/publish`,
        {
          datasheetHash,
        },
      )
      .then(({ data }) => data);
  }

  async postUpdateDataSheetByHash(
    datasheetHash: string,
    updateDatasheetDto: any,
  ): Promise<FilterSummaryInterface> {
    return await axios
      .post<FilterSummaryInterface>(
        `${this.baseUrl}/v1/datasheet/@me/:updateByHash`,
        {
          datasheetHash,
          updateDatasheetDto,
        },
      )
      .then(({ data }) => data);
  }

  async getDataSheetByHash(
    filterHash: string,
  ): Promise<FilterSummaryInterface> {
    return await axios
      .post<FilterSummaryInterface>(
        `${this.baseUrl}/v1/datasheet/@me/:getByFilterHash`,
        {
          filterHash,
        },
      )
      .then(({ data }) => data);
  }

  async getFilterMe(): Promise<any> {
    return await axios
      .get(`${this.baseUrl}/v1/filter/@me`)
      .then(({ data }) => data);
  }

  async getFilterMeId(id: string): Promise<any> {
    return await axios
      .get(`${this.baseUrl}/v1/filter/@me/${id}`)
      .then(({ data }) => data);
  }

  async getFilterMeByHash(value: string): Promise<FilterMeInterface> {
    return await axios
      .post<FilterMeInterface>(`${this.baseUrl}/v1/filter/@me/:getByHash`, {
        filterHash: value,
      })
      .then(({ data }) => data);
  }

  async createFilterMe(values: FilterMeInterface): Promise<FilterMeInterface> {
    return await axios
      .post<FilterMeInterface>(`${this.baseUrl}/v1/filter/@me`, values)
      .then(({ data }) => data);
  }
}
