import React, {
  Component,
  ErrorInfo,
  FC,
  lazy,
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  Routes as Router,
  Route,
  Navigate,
  Outlet,
  useSearchParams,
} from "react-router-dom";
import { useApi } from "../../Sdk/React/Hooks/ApiContext";
import { CartProvider } from "../Context/CartContext/Cart.context";
import { Header } from "../../Components/Header/Header";
import { Footer } from "../../Components/Footer/Footer";

const Index = lazy(async () => await import("../Modules/Index/Index"));
const Cart = lazy(async () => await import("../Modules/Cart/Cart"));
const Account = lazy(async () => await import("../Modules/Account/Account"));
const NewStatement = lazy(
  async () => await import("../Modules/NewStatement/NewStatement"),
);
const Catalog = lazy(() =>
  import("../Modules/Catalog/Catalog").then((module) => ({
    default: module.Catalog,
  })),
);
const LandingPartner = lazy(() =>
  import("../Modules/LandingPartner/LandingPartner").then((module) => ({
    default: module.LandingPartner,
  })),
);
const Pricing = lazy(() =>
  import("../Modules/Pricing/Pricing").then((module) => ({
    default: module.Pricing,
  })),
);
const Cookies = lazy(() =>
  import("../Modules/Documents/Cookies/Cookies").then((module) => ({
    default: module.Cookies,
  })),
);
const Login = lazy(() =>
  import("../Modules/Auth/Login").then((module) => ({
    default: module.Login,
  })),
);
const LoginImpersonate = lazy(() =>
  import("../Modules/Auth/LoginImpersonate").then((module) => ({
    default: module.LoginImpersonate,
  })),
);
const Contact = lazy(() =>
  import("../Modules/Contact/Contact").then((module) => ({
    default: module.Contact,
  })),
);
const Regulations = lazy(() =>
  import("../Modules/Documents/Regulations/Regulations").then((module) => ({
    default: module.Regulations,
  })),
);
const Register = lazy(() =>
  import("../Modules/Auth/Register").then((module) => ({
    default: module.Register,
  })),
);
const PrivatePolicy = lazy(() =>
  import("../Modules/Documents/PrivatePolicy/PrivatePolicy").then((module) => ({
    default: module.PrivatePolicy,
  })),
);
const PaymentStatus = lazy(() =>
  import("../Modules/PaymentStatus/PaymentStatus").then((module) => ({
    default: module.PaymentStatus,
  })),
);
const LandingPage = lazy(() =>
  import("../Modules/LandingPage/LandingPage").then((module) => ({
    default: module.LandingPage,
  })),
);
const Faq = lazy(() =>
  import("../Modules/Faq/Faq").then((module) => ({
    default: module.Faq,
  })),
);
const Gdpr = lazy(() =>
  import("../Modules/Documents/Gdpr/Gdpr").then((module) => ({
    default: module.Gdpr,
  })),
);
const Filter = lazy(() =>
  import("../Modules/Filter/Filter").then((module) => ({
    default: module.Filter,
  })),
);
const CurrentBase = lazy(() =>
  import("../Modules/CurrentBase/CurrentBase").then((module) => ({
    default: module.CurrentBase,
  })),
);

const MasterLayout = () => {
  return (
    <>
      {/*<ShowText />*/}
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};
const AuthLayout = () => {
  return (
    <>
      {/*<ShowText />*/}
      <Header isAuth={true} />
      <Outlet />
    </>
  );
};
const LandingPageLayout = () => {
  return (
    <section className={"landing-page"}>
      <div className="container">
        <div className="row">
          <Header isAuth={true} />
          <Outlet />
        </div>
      </div>
    </section>
  );
};

const LandingPartnerLayout = () => {
  return (
    <>
      <section className={"landing-page"}>
        <div className="container">
          <div className="row">
            <Outlet />
          </div>
        </div>
      </section>
    </>
  );
};

const CartLayout = () => {
  return (
    <CartProvider>
      <Outlet />
    </CartProvider>
  );
};

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    return this.props.children;
  }
}

export { ErrorBoundary };

const Routes: FC = () => {
  const { auth } = useApi();
  const [isLogged, setIsLogged] = useState(auth.getAccount());
  const [params] = useSearchParams();

  if (
    params.get("gclid") &&
    params.get("gclid") !== undefined &&
    params.get("gclid") !== "undefined"
  ) {
    localStorage.setItem("gclid", params.get("gclid"));
  }

  useEffect(() => {
    setInterval(() => {
      setIsLogged(auth.getAccount());
    }, 100);
  }, []);

  return (
    <ErrorBoundary>
      <Router>
        <Route path="/" element={<MasterLayout />}>
          <Route index element={<Index />} />
          <Route path={"/nowa-baza-firm"} element={<Filter />} />
          <Route path={"/nowa-baza-firm/:id"} element={<Filter />} />
          <Route path={"/baza-firm/:category"} element={<Catalog />} />
          <Route path={"/baza-firm/:category/:page"} element={<Catalog />} />
          <Route
            path={"/baza-firm/kategoria/:category"}
            element={<Catalog />}
          />
          <Route
            path={"/baza-firm/kategoria/:category/:id"}
            element={<Catalog />}
          />
          <Route
            path={"/baza-firm/kategoria/:category/:id/:page"}
            element={<Catalog />}
          />
          <Route path={"/katalog/:id"} element={<CurrentBase />} />
          <Route path={"/faq"} element={<Faq />} />
          <Route path={"/regulamin"} element={<Regulations />} />
          <Route path={"/polityka-prywatnosci"} element={<PrivatePolicy />} />
          <Route path={"/kontakt"} element={<Contact />} />
          <Route path={"/cennik"} element={<Pricing />} />
          <Route path={"/zasady-ochrony-danych-osobowych"} element={<Gdpr />} />
          <Route path={"/polityka-cookies"} element={<Cookies />} />
          {isLogged && (
            <>
              <Route
                path={"/nowe-zestawienie/:id"}
                element={
                  <CartProvider>
                    <NewStatement />
                  </CartProvider>
                }
              />
              <Route
                path={"/konto"}
                element={
                  <CartProvider>
                    <Account />
                  </CartProvider>
                }
              />
            </>
          )}
        </Route>
        <Route path="/" element={<AuthLayout />}>
          <Route path={"/logowanie"} element={<Login />} />
          <Route
            path={"/logowanie/impersonate"}
            element={<LoginImpersonate />}
          />
          <Route path={"/rejestracja"} element={<Register />} />
        </Route>
        <Route path="/" element={<LandingPageLayout />}>
          <Route path={"/lp"} element={<LandingPage />} />
        </Route>
        <Route path="/" element={<LandingPartnerLayout />}>
          <Route path={"/ref/leadakademia"} element={<LandingPartner />} />
        </Route>
        {isLogged && (
          <>
            <Route path="/koszyk" element={<CartLayout />}>
              <Route index element={<Cart />} />
            </Route>
            <Route path={"/status-platnosci/:id"} element={<AuthLayout />}>
              <Route
                index
                element={
                  <CartProvider>
                    <PaymentStatus />
                  </CartProvider>
                }
              />
            </Route>
          </>
        )}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Router>
      {/*</ScrollToTop>*/}
    </ErrorBoundary>
  );
};

export default Routes;
