import React from "react";
import { toAbsoluteUrl } from "../../Utils/Assets/AssetHelpers";
import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import clsx from "clsx";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-6">
            <div className={styles.footerTop}>
              <img
                alt={"logo obeg stopka"}
                loading="lazy"
                src={toAbsoluteUrl("/media/logo/logo-footer.png")}
              />
            </div>
            <h4>Potrzebujesz pomocy lub masz pytanie?</h4>
            <p>
              Napisz do nas na{" "}
              <a href={"mailto:pomoc@obeg.pl"}>pomoc@obeg.pl</a>
            </p>
          </div>
          <div className="col-12 col-sm-6 col-lg-2">
            <div className={styles.footerTop}>
              <h4>Informacje</h4>
            </div>
            <ul className={styles.footerInformation}>
              <li>
                <Link to={"/regulamin"}>Regulamin</Link>
              </li>
              <li>
                <Link to={"/faq"}>FAQ</Link>
              </li>
              <li>
                <Link to={"/polityka-cookies"}>Polityka cookies</Link>
              </li>
              <li>
                <Link to={"/polityka-prywatnosci"}>Polityka prywatności</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-6 col-lg-2">
            <div className={styles.footerTop}>
              <h4>obeg</h4>
            </div>
            <ul className={styles.footerInformation}>
              <li>
                <Link to={"/kontakt"}>Kontakt</Link>
              </li>
              <li>
                <Link to={"/cennik"}>Cennik</Link>
              </li>
              <li>
                <Link to={"/baza-firm/branze"}>Katalog</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-6 col-lg-2">
            <div className={styles.footerTop}>
              <h4>Social media</h4>
            </div>
            <ul className={styles.footerInformation}>
              <li className={"mb-3"}>
                <a
                  target={"_blank"}
                  href={"https://www.linkedin.com/company/obegpl/"}
                  rel="noreferrer nofollow"
                >
                  <img
                    width={"32px"}
                    height={"32px"}
                    className={"me-2"}
                    loading="lazy"
                    src={toAbsoluteUrl("/media/footer/linkedin.png")}
                    alt={"baza firm obeg logo linkedin"}
                  />
                  LinkedIn
                </a>
              </li>
              <li className={"mb-3"}>
                <a
                  target={"_blank"}
                  href={"https://www.facebook.com/obegpl"}
                  rel="noreferrer nofollow"
                >
                  <img
                    width={"32px"}
                    height={"32px"}
                    className={"me-2"}
                    loading="lazy"
                    src={toAbsoluteUrl("/media/footer/facebook.png")}
                    alt={"baza firm obeg logo faceoobk"}
                  />
                  Facebook
                </a>
              </li>
              <li className={"mb-3"}>
                <a
                  target={"_blank"}
                  href={"https://twitter.com/obeg_pl"}
                  rel="noreferrer nofollow"
                >
                  <img
                    width={"32px"}
                    height={"32px"}
                    className={"me-2"}
                    loading="lazy"
                    src={toAbsoluteUrl("/media/footer/twitter.png")}
                    alt={"baza firm obeg logo twitter"}
                  />
                  Twitter
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className={clsx("col-12 col-lg-6", styles.footerCopyright)}>
            <p>
              © {new Date().getFullYear()} Obeg.pl - Bazy firm CEIDG, KRS, REGON
            </p>
          </div>
          <div className={clsx("col-12 col-lg-6", styles.footerCopyright)}>
            <a
              title={"pozycjonowanie stron"}
              href={"//foxyseo.pl"}
              rel="noreferrer"
              className={
                "text-end d-flex align-items-center justify-content-lg-end"
              }
              target={"_blank"}
            >
              <p className={"me-2"}>Pozycjonowanie stron</p>
              <img
                alt={"logo foxyseo"}
                height={"30"}
                loading="lazy"
                src={
                  "https://foxyseo.pl/wp-content/uploads/2022/07/logoFoxySeo-1-e1656786211399.png"
                }
              />
            </a>
          </div>
        </div>
        <div className="row">
          <div
            className="col-12 mt-4"
            style={{ fontSize: "14px", color: "#9499A3" }}
          >
            W naszym serwisie przetwarzamy dane osobowe dotyczące działalności
            gospodarczej i zawodowej prowadzonej przez osoby fizyczne w celu
            zwiększenia pewności, stabilności i bezpieczeństwa obrotu
            gospodarczego. Więcej informacji znajdziesz w{" "}
            <Link
              to={"/polityka-prywatnosci"}
              className={"text-decoration-underline"}
            >
              polityka prywatności
            </Link>{" "}
            i{" "}
            <Link
              to={"/zasady-ochrony-danych-osobowych"}
              className={"text-decoration-underline"}
            >
              zasady ochrony danych osobowych
            </Link>
            .
          </div>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
