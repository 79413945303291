import React, { createContext, useContext, useEffect, useState } from "react";
import { useApi } from "../../../Sdk/React/Hooks/ApiContext";
import { UserInterface } from "../../../Sdk/Services/User/User";

interface ProviderProps {
  children: React.ReactNode;
}

export const AccountContext = createContext({
  data: {} as UserInterface,
  updateAccount: () => {},
});

export const AccountProvider = ({ children }: ProviderProps) => {
  const { auth } = useApi();
  const { logout } = auth;

  const [state, setState] = useState({
    data: {
      _id: "",
      email: "",
      roles: [],
    },
    updateAccount: () => {},
  });

  const account = () => {
    return auth
      .getUserInfo()
      .then((user) => {
        if (user) {
          setState(() => ({
            ...state,
            data: {
              _id: user._id,
              email: user.email,
              roles: user.roles,
            },
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        logout();
      });
  };

  useEffect(() => {
    account();
  }, []);

  const updateAccount = () => {
    account();
  };

  return (
    <AccountContext.Provider
      value={{
        data: state.data,
        updateAccount,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => useContext(AccountContext);
