import axios from "axios";
import { RequestExportFileInterface } from "./Datasheet";

export interface DatasheetApiInterface {
  createExport(
    param: RequestExportFileInterface,
  ): Promise<RequestExportFileInterface>;
}

export class DatasheetService implements DatasheetApiInterface {
  constructor(private baseUrl: string) {}

  async createExport(
    param: RequestExportFileInterface,
  ): Promise<RequestExportFileInterface> {
    return await axios
      .post(`${this.baseUrl}/v1/datasheet/@me/createExportFile`, param, {
        responseType: "blob",
      })
      .then(({ data }) => data);
  }
}
