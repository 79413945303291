import React from "react";
import styles from "./ErrorLayout.module.css";
import { toAbsoluteUrl } from "../../Utils/Assets/AssetHelpers";

const ErrorLayout = () => {
  return (
    <div className={styles.flex}>
      <div className={styles.block}>
        <img
          src={toAbsoluteUrl("/media/logo/logo.png")}
          height={"48px"}
          width={"270px"}
          alt="logo obeg"
        />
        <h3>
          Korzystasz ze starej wersji aplikacji, odśwież aplikację klikając
          poniższy przycisk
        </h3>
        <button className={styles.btn} onClick={() => window.location.reload()}>
          Odśwież stronę
        </button>
      </div>
    </div>
  );
};

export { ErrorLayout };
