import React, { createContext, useContext, useState } from "react";
import { AddressDocsInterface } from "../../../Sdk/Services/Address/Address";
import { toast } from "react-toastify";
import { EntityStatusEnum } from "../../../Sdk/Services/Filter/Filter";
import { getEnumKeyByEnumValue, LegalForm } from "../../Common/LegalForm";

interface ProviderProps {
  children: React.ReactNode;
}

export const NewBaseContext = createContext({
  data: {
    status: [EntityStatusEnum.ACTIVE],
    has_telephone_number: "",
    has_email_address: "",
    has_website_address: "",
    from: "",
    to: "",
    main_pkd: [],
    pkd: [],
    location: [],
    legalForm: [getEnumKeyByEnumValue(LegalForm, LegalForm.JDG)],
    filterPreview: false,
  },
  checkboxValue: (target: string, field: string, value: boolean) => {},
  setPreview: (field: string, value: boolean | string) => {},
  setPreviewArray: (
    field: string,
    value: Array<number | EntityStatusEnum>,
  ) => {},
  clearValueFromArray: (field: string, value: string | EntityStatusEnum) => {},
  addLocation: (object: AddressDocsInterface) => {},
  addLegalForm: (object: any) => {},
  clearLocation: (object: AddressDocsInterface) => {},
  clearLegalForm: (object: any) => {},
  setAll: (object: object) => {},
  clearAll: () => {},
});

const InitialState = {
  status: [EntityStatusEnum.ACTIVE],
  has_telephone_number: "",
  has_email_address: "",
  has_website_address: "",
  from: "",
  to: "",
  main_pkd: [],
  pkd: [],
  location: [],
  legalForm: [getEnumKeyByEnumValue(LegalForm, LegalForm.JDG)],
  filterPreview: false,
};

export const NewBaseProvider = ({ children }: ProviderProps) => {
  const [state, setState] = useState({
    data: InitialState,
    checkboxValue: (target: string, field: string, value: boolean) => {},
    setPreview: (field: string, value: boolean | string) => {},
    setPreviewArray: (field: string, value: string[]) => {},
    clearValueFromArray: (field: string, value: string) => {},
    addLocation: (object: AddressDocsInterface) => {},
    addLegalForm: (object: any) => {},
    clearLocation: (object: AddressDocsInterface) => {},
    clearLegalForm: (object: any) => {},
    setAll: (object: object) => {},
  });

  const checkboxValue = (target: string, field: string, value: boolean) => {
    setState((state) => ({
      ...state,
      data: {
        ...state.data,
        [target]: {
          ...state.data[target],
          [field]: value,
        },
      },
    }));
  };

  const setAll = (object: object) => {
    setState((state) => ({
      ...state,
      data: {
        ...state.data,
        ...object,
      },
    }));
  };

  const setPreview = (field: string, value: boolean | string) => {
    setState((state) => ({
      ...state,
      data: {
        ...state.data,
        [field]: value,
      },
    }));
  };

  const setPreviewArray = (field: string, value: number[]) => {
    setState((state) => ({
      ...state,
      data: {
        ...state.data,
        [field]: [...state.data[field], ...value],
      },
    }));
  };
  const clearValueFromArray = (field: string, value: string) => {
    const oldData = state.data[field];
    const newData = oldData.filter((elm: string) => elm !== value);
    setState((state) => ({
      ...state,
      data: {
        ...state.data,
        [field]: newData,
      },
    }));
  };

  const addLocation = (object: AddressDocsInterface) => {
    const location = state.data.location;
    const exist = location.find((location) => {
      if (location._id) {
        return location._id === object._id;
      } else {
        return (
          location.text === object.text && location.province === object.province
        );
      }
    });
    if (!exist) {
      setState((state) => ({
        ...state,
        data: {
          ...state.data,
          location: [...location, object],
        },
      }));
    } else {
      toast.error("Nie można dodać tej samej lokazliacji.");
    }
  };

  const clearLocation = (object: AddressDocsInterface) => {
    const location = state.data.location;
    const filtered = location.filter((location) => {
      if (location._id) {
        return location._id !== object._id;
      } else {
        return (
          location.text !== object.text && location.province !== object.province
        );
      }
    });

    setState((state) => ({
      ...state,
      data: {
        ...state.data,
        location: filtered,
      },
    }));
  };

  const addLegalForm = (object) => {
    const legalForm = state.data.legalForm;
    const exist = legalForm.find((legalForm) => {
      if (legalForm) {
        return legalForm === object;
      }
    });
    if (!exist) {
      setState((state) => ({
        ...state,
        data: {
          ...state.data,
          legalForm: [...legalForm, object],
        },
      }));
    } else {
      toast.error("Nie można dodać tej samej formy prawnej.");
    }
  };

  const clearLegalForm = (object) => {
    const legalForm = state.data.legalForm;

    const filtered = legalForm.filter((legalForm) => legalForm !== object);

    setState((state) => ({
      ...state,
      data: {
        ...state.data,
        legalForm: filtered,
      },
    }));
  };

  const clearAll = () => {
    setState((state) => ({
      ...state,
      data: InitialState,
    }));
  };

  return (
    <NewBaseContext.Provider
      value={{
        data: state.data,
        checkboxValue,
        setPreview,
        setPreviewArray,
        clearValueFromArray,
        clearAll,
        clearLocation,
        clearLegalForm,
        addLocation,
        addLegalForm,
        setAll,
      }}
    >
      {children}
    </NewBaseContext.Provider>
  );
};

export const useNewBase = () => useContext(NewBaseContext);
