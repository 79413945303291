import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App/App";
import reportWebVitals from "./reportWebVitals";
import { mockAxios, setupAxios } from "./Setup";
import axios from "axios";
import * as serviceWorker from "./serviceWorker";

// if (ENABLE_MOCKS === 'true') {
/* const mock = */
// mockAxios(axios);
// }

setupAxios(axios);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  // </React.StrictMode>,
);

serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
