import React, { FC } from "react";
import clsx from "clsx";

interface LoadingProps {
  isMini?: boolean;
  toSlow?: boolean;
}

const Loading: FC<LoadingProps> = ({ isMini = false, toSlow = false }) => {
  return (
    <>
      <div className={clsx("lds", isMini ? "w-100 h-100" : "")}>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {toSlow && (
          <span
            className={"d-flex flex-column"}
            style={{
              position: "absolute",
              marginTop: "240px",
              transition: "all .4s",
              fontWeight: 500,
            }}
          >
            <span>
              Trwa generowanie wyników, może to potrwać nawet kilka minut.
            </span>
          </span>
        )}
      </div>
    </>
  );
};

export default Loading;
