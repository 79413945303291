import axios from "axios";
import {
  BalanceInterface,
  BillingInterface,
  ContactInterface,
  InvoiceDtoInterface,
  PasswordInterface,
  TransactionsInterface,
  UsersInterface,
} from "./User";

export interface UserApiInterface {
  getTransactions(): Promise<TransactionsInterface>;
  sendContactMessage(param: ContactInterface): Promise<ContactInterface>;
  getBilling(): Promise<BillingInterface>;
  postBilling(param: BillingInterface): Promise<BillingInterface>;
  postOrder(param: any): Promise<any>;
  getOrder(id: string): Promise<any>;
  getStatusOrder(id: string): Promise<any>;
  updatePassword(param: PasswordInterface): Promise<PasswordInterface>;
  getUserInfo(): Promise<any>;
  getUsers(): Promise<UsersInterface>;
  updateUserInfo(id: string, param: any): Promise<any>;
  getBalance(): Promise<BalanceInterface>;
  getInvoice(): Promise<InvoiceDtoInterface>;

  getInvoicePdf(orderId: string): Promise<any>;
}

export class UserService implements UserApiInterface {
  constructor(private baseUrl: string) {}

  async getTransactions(): Promise<TransactionsInterface> {
    return await axios
      .get(`${this.baseUrl}/v1/user/@me/transactions`)
      .then(({ data }) => data);
  }

  async getUsers(): Promise<UsersInterface> {
    return await axios.get(`${this.baseUrl}/v1/user`).then(({ data }) => data);
  }

  async getBilling(): Promise<BillingInterface> {
    return await axios
      .get(`${this.baseUrl}/v1/user/@me/billing`)
      .then(({ data }) => data);
  }

  async getBalance(): Promise<BalanceInterface> {
    return await axios
      .get(`${this.baseUrl}/v1/user/@me/balance`)
      .then(({ data }) => data);
  }

  async postBilling(param: BillingInterface): Promise<BillingInterface> {
    return await axios
      .post(`${this.baseUrl}/v1/user/@me/billing`, param)
      .then(({ data }) => data);
  }

  async postOrder(param: any): Promise<any> {
    return await axios
      .post(`${this.baseUrl}/v1/order`, param)
      .then(({ data }) => data);
  }

  async getStatusOrder(id: string): Promise<any> {
    return await axios
      .get(`${this.baseUrl}/v1/order/${id}/status`)
      .then(({ data }) => data);
  }

  async getOrder(id: string): Promise<any> {
    return await axios
      .get(`${this.baseUrl}/v1/order/${id}`)
      .then(({ data }) => data);
  }

  async sendContactMessage(param: ContactInterface): Promise<ContactInterface> {
    return await axios
      .post(`${this.baseUrl}/v1/user/contact`, param)
      .then(({ data }) => data);
  }

  async updatePassword(param: PasswordInterface): Promise<PasswordInterface> {
    return await axios
      .patch(`${this.baseUrl}/v1/user/@me/password`, param)
      .then(({ data }) => data);
  }
  async getUserInfo(): Promise<any> {
    return await axios
      .get(`${this.baseUrl}/v1/user/@me`)
      .then(({ data }) => data);
  }
  async updateUserInfo(id: string, param: any): Promise<any> {
    return await axios
      .patch(`${this.baseUrl}/v1/user/${id}`, param)
      .then(({ data }) => data);
  }
  async getInvoice(): Promise<InvoiceDtoInterface> {
    return await axios
      .get(`${this.baseUrl}/v1/invoice`)
      .then(({ data }) => data);
  }

  async getInvoicePdf(orderId: string): Promise<InvoiceDtoInterface> {
    return await axios
      .get(`${this.baseUrl}/v1/invoice/generate/${orderId}`)
      .then(({ data }) => data);
  }
}
