import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

export default function setupAxios(axios: AxiosInstance) {
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const json = localStorage.getItem("obeg-auth");
      if (json) {
        const { accessToken } = JSON.parse(json);
        if (accessToken && config.headers) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }

      return config;
    },
    (error: AxiosError) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      return Promise.reject({ ...error });
    },
  );
}
