import { AuthApiInterface, AccountService } from "./Services/Auth/Service";
import { FilterApiInterface, FilterService } from "./Services/Filter/Service";
import { UserApiInterface, UserService } from "./Services/User/Service";
import {
  DatasheetApiInterface,
  DatasheetService,
} from "./Services/Datasheet/Service";
import {
  AddressApiInterface,
  AddressService,
} from "./Services/Address/Service";

export interface ObegApiOptions {
  baseUrl: string;
}

export class ObegApi {
  readonly auth: AuthApiInterface;
  readonly filter: FilterApiInterface;
  readonly user: UserApiInterface;
  readonly datasheet: DatasheetApiInterface;
  readonly address: AddressApiInterface;

  constructor(private options: ObegApiOptions) {
    this.auth = new AccountService(`${options.baseUrl}`);
    this.filter = new FilterService(`${options.baseUrl}`);
    this.user = new UserService(`${options.baseUrl}`);
    this.datasheet = new DatasheetService(`${options.baseUrl}`);
    this.address = new AddressService(`${options.baseUrl}`);
  }
}
