// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LdjhFVaJp2cwdJDeWuUW {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-top: 20vh;\n}\n.QerB_SxSDUmAJVEEAu1U {\n    max-width: 500px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.AOjsyHbaqOSIkJcjKPWq {\n    color: #fff;\n    background-color: #2875a6 !important;\n    border-color: #2875a6 !important;\n    border-radius: 60px;\n    padding: 15px 50px;\n    margin-top: 30px;\n    font-weight: 500;\n    font-size: 16px;\n    border: unset;\n    cursor: pointer;\n}\n.LdjhFVaJp2cwdJDeWuUW h3 {\n    margin-top: 30px;\n    font-size: 18px;\n    font-weight: 500;\n    text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./Components/ErrorLayout/ErrorLayout.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,oCAAoC;IACpC,gCAAgC;IAChC,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,aAAa;IACb,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".flex {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-top: 20vh;\n}\n.block {\n    max-width: 500px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.btn {\n    color: #fff;\n    background-color: #2875a6 !important;\n    border-color: #2875a6 !important;\n    border-radius: 60px;\n    padding: 15px 50px;\n    margin-top: 30px;\n    font-weight: 500;\n    font-size: 16px;\n    border: unset;\n    cursor: pointer;\n}\n.flex h3 {\n    margin-top: 30px;\n    font-size: 18px;\n    font-weight: 500;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flex": "LdjhFVaJp2cwdJDeWuUW",
	"block": "QerB_SxSDUmAJVEEAu1U",
	"btn": "AOjsyHbaqOSIkJcjKPWq"
};
export default ___CSS_LOADER_EXPORT___;
